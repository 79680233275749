import Layout from "../../components/common/Layout"
import ArrowZ from "../../components/common/ArrowZ"
import CareersLink from "../../components/common/CareersLink"
import Footer from "../../components/common/Footer"
import React from "react"
import Kv from "../../components/section/company/Kv"
import Main from "../../components/section/company/Main"

export default function Company() {
  let title = "COMPANY"
  let description =
    "「レストラン体験を再定義することで、あらゆる場所でリアルなつながりをつくる。」テクノロジーを利用して非連続な成長と高い収益率を実現する「コネクティッド・レストラン」を構築します"
  let headTtl = "COMPANY"
  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="company" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Kv headTtl={headTtl} />
              <Main />
              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}
